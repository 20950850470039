@import "./../../../public/variables.scss";

.info {
  max-width: 800px;
  width: 100%;
  cursor: default;
  &__box {
    display: flex;
  }
  &__img {
    width: 280px;
    padding: 20px;
    border-radius: 30px;
  }
  &__boxText {
    max-width: 500px;
    width: 100%;
  }
  &__list {
    margin: 0 0 0 -30px;
    padding: 0;
    list-style-type: none; /* Убираем маркеры */
    max-width: 800px;
    width: 100%;
  }
  &__listSmall {
    list-style-type: circle !important;
  }
  &__text {
    @extend %text17-20;
    text-decoration: none;
    color: $classicBlack;
    max-width: 350px;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
/* p{
  margin-left: 50px;
} */

@media only screen and (max-width: 1200px) {
  .info {
    max-width: 700px;
    width: 100%;
  }
}

@media only screen and (max-width: 950px) {
  .info {
    max-width: 800px;
    width: 100%;
    cursor: default;
    &__box {
      flex-direction: column-reverse;
    }
  }
}

@media only screen and (max-width: 600px) {
  .info {
    max-width: 350px;
    width: 100%;
    cursor: default;
    &__box {
      flex-direction: column-reverse;
    }
    &__boxText {
      max-width: 250px;
      width: 100%;
    }
    &__img {
      width: 200px;
      padding: 10px;
      border-radius: 30px;
    }
  }
}

