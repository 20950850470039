@import "./../../../public/variables.scss";

.contacts {
  @extend %text17-20;

  &__title {
    @extend %text17-20;
    list-style-type: none;
  }
  &__block {
    list-style-type: none;
    margin-bottom: 30px;
    max-width: 800px;
    width: 100%;
    padding: 0;
    margin: 0 0 30px -30px;
  }
  &__text {
    @extend %text17-20;
    position: relative;
    display: block;
    padding: 30px;
    margin: 30px;
    background: #a8d5e5;
    color: black;
    text-decoration: none;
    border-radius: 5px;
    transition: 0.3s ease-out;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  }
  &__img {
    height: 30px;
    margin-right: 10px;
  }
  &__text_hover {
    // max-width: 1000px;
  //  width: 100%;
    position: relative;
    display: flex;
    padding: 30px;
    margin: 30px;
    background: #a8d5e5;
    color: black;
    text-decoration: none;
    border-radius: 5px;
    transition: 0.3s ease-out;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    align-items: center;
  }
}

.contacts__text_hover:hover {
  outline: 2px dashed #3d1f15; /* Ширина, вид и цвет рамки */
  outline-offset: -10px;
  opacity: 0.6;
}

@media only screen and (max-width: 950px) {
  .contacts {
    &__block {
      max-width: 600px;
    }
    &__text_hover {
      max-width: 380px;
      padding: 30px 0 30px 30px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .contacts {
    &__block {
      max-width: 250px;
    }
    &__text_hover {
      max-width: 200px;
    }
    &__text{
      max-width: 200px;
      text-decoration: none;
      color: initial;
    }
  }
}
