.bm-burger-button {
 
    position: fixed;
    width: 60px;
    left: 15px;
    top: 10px;
  }
  .bm-burger-button:hover{
    opacity: 0.6;
  }
  .bm-burger-bars {
    background: #373a47;
  }
  .bm-menu {
    padding-top: 10px;
    margin-top: -60px;
    margin-left: -25px;
  }
  .bm-overlay{
    margin-top: -30px;
  }
  .bm-item{
    max-width: 200px
   ;
  }
  @media only screen and (max-width: 400px) {
    .bm-burger-button {
 
      position: fixed;
      width: 20px;
      left: 5px;
      top: 10px;
    }
  }