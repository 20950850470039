@import "./../public/variables.scss";

::-webkit-scrollbar {
  width: 0;
}
////////////////////
.app {
  background: $coverColor;
  &__outerContainer {
    @extend %text17-20;
    z-index: 1;
    padding-bottom: 300px;
    margin: 0;
    padding: 0;
   // padding: 30px 0 30px 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 150vh;
    background-image: url("./images/pngwing.png");
    background-repeat: space;
    background-size: 200px 200px;
    background-position: center;
    height: 100%;
  }

  &__imgFlags {
    width: 25px;
    height: 20px;
  }
  &__buttonFlags {
    border: none;
    color: initial;
    width: 25px;
    height: 20px;
    cursor: pointer;
    margin-top: 5px;
    margin-right: 30px;
    &:hover {
      opacity: 0.5;
    }
  }
  &__buttonFlagsCover {
    //max-width: 1200px;
    width: 100%;
    text-align: right;
  }
  &__menuBox {
    // max-width: 70px;
    width: 100%;
  }
  &__menuItem {
    max-width: 600px;
    width: 100%;
    //font-size: 20px;
    position: relative;
    display: flex;
    padding: 30px;
    margin: 30px;
    background: #a8d5e5;
    color: black;
    text-decoration: none;
    border-radius: 5px;
    transition: 0.3s ease-out;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    align-items: center;

    &:hover {
      background-color: #165a72;
    }
  }
  &__menuImg {
    max-width: 50px;
    width: 100%;
    height: 100%;
  }
  &__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__menuButtons {
    font-family: $stock-font !important;
    border: none;
    background-image: url("./images/pngwing.png");
    background-repeat: space;
    background-size: 200px 200px;
    width: 100%;
    max-width: 1000px;
    @extend %text17-20;
    padding: 15px 15px 15px 100px;
    background-color: #a8d5e5;
    margin: 10px;
    appearance: none;
    border: none;
    outline: none;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  &__menuButton {
    font-family: $stock-font !important;
    border: none;
    padding: 15px;
    margin: 25px 5px 25px 5px;
    line-height: 20px;
    border-radius: 0;
    appearance: none;
    color: black;
    cursor: pointer;
    &:hover {
      background-color: #165a72;
      cursor: pointer !important;
    }
  }
  &__menuItemBox {
    // font-size: 20px;
    border-radius: 10px;
    position: relative;
    display: block;
    padding: 30px 30px 30px 100px;
    background: #a8d5e5;
    color: black;
    text-decoration: none;
    transition: 0.3s ease-out;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    max-width: 870px;
    min-width: 870px;
    width: 100%;
    margin: 30px;
    background-image: url("./images/pngwing.png");
    background-repeat: space;
    background-size: 200px 200px;
    background-position: center;
  }

  &__menuItemLink {
    @extend %title24-29;
    color: inherit;
    cursor: default ;
    text-decoration: none;
    padding: 0;
    margin: 0;
    //font-size: 26px;
    line-height: 30px;
    color: #165a72;
  }
}

/* li {
  max-width: 600px;
  width: auto;
}
h1 {
  color: #165a72;
  margin: 50px auto;
 // font-size: 40px;
}
.menu1 {
  margin-bottom: 40px;
  margin-top: 20px;
  width: initial;
} */
/* h2 {
  color: #165a72;
} */

@media only screen and (max-width: 1200px) {
  .app {
    &__nav {
      margin-left: 100px;
    }
    &__menuItemBox {
      padding: 20px 20px 20px 30px;
      max-width: 730px;
      min-width: 730px;
    }
    &__menuButtons {
      max-width: 800px;
    }
  }
}
@media only screen and (max-width: 950px) {
  .app {
    &__nav {
      margin-left: 100px;
    }
    &__menuItemBox {
      padding: 10px 10px 10px 30px;
      max-width: 450px;
      min-width: 450px;
    }
    &__menuButtons {
      max-width: 490px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .app {
    &__nav {
      max-width: 300px;
    }
    &__menuItemBox {
      background-size: 50px 50px;
      padding: 10px;
      max-width: 250px;
      min-width: 50px;
    }
    &__menuButtons {
      padding: 15px;
      max-width: 230px;
    }
    &__outerContainer {
      background-size: 50px 50px;
    }
  }
}
@media only screen and (max-width: 400px) {
  .app {
    &__nav {
      max-width: 300px;
      margin-left: 15px;
    }
    &__imgFlags {
      width: 18px;
      max-width: 20px;
      height: 18px;
    }
   
    &__buttonFlags {
      width: 20px;
      height: 20px;
      top: 9px;
      left: 50px;
    }
  }
}
