@import "./../../../public/variables.scss";

.skills {
  @extend %text17-20;
  cursor: default;

  &__box {
    display: flex;
    align-items: center;
  }
  &__img {
    padding-right: 10px;
    height: 48px;
  }
  &__block {
    margin-bottom: 30px;
    max-width: 800px;
    width: 100%;
    padding: 0;
    margin-left: -30px;
  }
  &__text {
    position: relative;
    display: block;
    padding: 30px;
    margin: 30px;
    background: #a8d5e5;
    color: black;
    text-decoration: none;
    border-radius: 5px;
    transition: 0.3s ease-out;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  }
}

@media only screen and (max-width: 600px) {
  .skills {
    &__title {
      line-height: 45px;
    }

    &__block {
      margin-bottom: 30px;
      max-width: 350px;
      width: auto;
    }

    &__text {
      padding: 10px 5px 10px 5px;
   
    }
  }
}
