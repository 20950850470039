@import "./../../../public/variables.scss";

.project {
  @extend %text17-20;
  color: black;
  flex: 1 0 auto;
  max-width: 800px;
  width: 100%;
  &__linkCover {
    background-color: $coverColor;
    padding: 20px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
    border-radius: 10px;
    //max-width: 300px;
    height: 100%;
    width: 100%;

    &:hover {
      opacity: 0.6;
    }
  }
  &__link {
    max-width: 1000px;
    width: 100%;
    position: relative;
    display: flex;
    padding: 30px;
    margin: 30px;
    background: $lightBlue;
    color: $classicBlack;
    text-decoration: none;
    border-radius: 5px;
    transition: 0.3s ease-out;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    align-items: center;
  }
  &__text {
    @extend %text17-20;
    text-decoration: none;
    color: $classicBlack;
    max-width: 350px;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  &__img {
    width: 300px;
    border-radius: 10px;
  }
  &__linkText {
    margin: 10px 0;
    @extend %text17-20;
    text-decoration: none;
    border-radius: 10px;
    color: $classicBlack;
  }
  &__linkTextSmall {
    margin: 0;
    padding: 0;
    color: $classicBlack;
    @extend %text14-17;
  }
  &__link {
    display: flex;
    width: 350px;
    flex-direction: column;
    align-items: flex-start;
  }
  &__list {
    margin: 0 0 0 -30px;
    padding: 0;
    list-style-type: none; /* Убираем маркеры */
    max-width: 800px;
    width: 100%;
  }
  &__listSmall {
    list-style-type: circle !important;
  }
  &__listBox1,&__listBox2 {
    display: flex;
    padding: 10px;
    margin-top: 20px;
    margin-left: 20px;
    max-width: 800px;
    width: 100%;
    align-items: center;
  }
  &__textBox {
    cursor: default;
    max-width: 350px;
    min-width: 350px;
    width: 100%;
    max-width: 350px;
    min-width: 350px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__createLine {
    width: 800px;
    border-top: 2px solid $midleBlue;
    opacity: 0.6;
  }
}
@media only screen and (max-width: 1200px) {
  .project {
    &__listBox1,&__listBox2 {
      max-width: 700px;
    }
    &__linkCover {
      max-width: 300px;
    }
    &__link {
      width: 300px;
    }
    &__text {
      max-width: 300px;
    }
    &__textBox {
      max-width: 300px;
      min-width: 300px;
      max-width: 300px;
      min-width: 300px;
    }
    &__createLine {
      width: 780px;
    }
  }
}

@media only screen and (max-width: 950px) {
  .project {
    &__listBox1{
      max-width: 600px;
      flex-direction: column;

 
  }
  &__listBox2{
    max-width: 600px;
    flex-direction: column-reverse;
  }
  &__createLine {
    width: 490px;
  }
}

}
@media only screen and (max-width: 600px) {
  .project {
    &__linkCover {
      max-width: 200px;
    }
    &__link {
      width: 200px;
    }
    &__text {
      max-width: 200px;
    }
    &__textBox {
      max-width: 200px;
      min-width: 200px;
      max-width: 200px;
      min-width: 200px;
    }
    &__listBox1{
      max-width: 300px;
  }
  &__img {
    width: 180px;
   ;
  }
  &__listBox2{
    max-width: 300px;
    flex-direction: column-reverse;
  }
  &__createLine {
    width: 270px;
    margin-left: 21px;
  }
}

}